import { Auth } from "aws-amplify";

export default {
  name: "login",
  methods: {
    async authenticate() {
      await Auth.federatedSignIn({ provider: "Okta-Internal" });
    }
  },
  computed: {
    backgroundImage() {
      return process.env.BASE_URL + "assets/media/bg/bg-4.jpg";
    }
  }
};
